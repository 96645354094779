import i18n from "@/libs/i18n";
import { ref } from "@vue/composition-api";

export default function useProductCertificate() {
  const tableColumns = [
    {
      key: "count",
      label: "#",
      sortable: false,
      thClass: "",
    },
    {
      key: "url",
      label: "File",
      sortable: false,
      thClass: "text-center",
    },
    {
      key: "name",
      label: i18n.t("lbl_name"),
      sortable: false,
      thClass: "",
    },
    {
      key: "verify_state",
      label: i18n.t("lbl_verification_status"),
      sortable: false,
      thClass: "text-center",
    },
    {
      key: "action",
      label: "",
      thClass: "text-right",
    },
  ];

  const refProductCertificatesTable = ref(null);
  const showTable = ref(false);

  const refetchData = () => {
    refProductCertificatesTable.value.refresh();
  };

  return {
    refProductCertificatesTable,
    tableColumns,
    showTable,
    refetchData,
  };
}
