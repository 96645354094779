<template>
  <div>
    <!-- <pre>1.{{ is_changed_category === 1 }} 2.{{ is_changed_category === 2 }} 3.{{ (!certificatesData) }} 4.{{ !certi_doc.length }}</pre> -->
    <b-card
      no-body
      v-if="
        (is_changed_category === 1 ||
          (is_changed_category === 2 &&
            (!certificatesData || !certificatesData.length) &&
            !certi_doc.length)) &&
        !disable
      "
    >
      <h5 class="border-left-primary pl-50 mb-1" style="border-left-width: 4px">
        {{ $t("lbl_list_of_requirements_for_uploading_certificates") }}
      </h5>
      <div>
        <validation-observer ref="uploadCertificatesValidation">
          <b-overlay :show="isLoading">
            <b-form>
              <div v-if="!certificatesForm">
                <b-alert variant="warning" show>
                  <div class="alert-body">
                    {{
                      $t(
                        "lbl_certificate_uploading_requirements_have_not_been_determined__please_select_a_category_in_the_product_info_tab_"
                      )
                    }}
                  </div>
                </b-alert>
              </div>
              <div v-else>
                <div v-if="certificatesForm.length === 0">
                  <b-alert variant="warning" show>
                    <div class="alert-body">
                      {{
                        $t(
                          "lbl_there_isn_t_any_required_document_for_this_category_"
                        )
                      }}
                    </div>
                  </b-alert>
                </div>
                <template v-else>
                  <b-row
                    v-for="(cerItem, index) in certificatesForm"
                    :key="`cerItem-${cerItem.id}`"
                  >
                    <b-col cols="12">
                      <b-form-group :label="cerItem.name" class="mb-2 required">
                        <validation-provider
                          #default="{ errors }"
                          :name="cerItem.name"
                          rules="required"
                        >
                          <b-form-file
                            :ref="`refInputCertificateEl${cerItem.id}`"
                            v-model="certificatesForm[index].file"
                            :placeholder="$t('lbl_choose_file')"
                            :disabled="disable"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col cols="12">
                      <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="primary"
                        @click="handleUploadCertificate"
                      >
                        <feather-icon icon="UploadIcon" class="mr-50" />
                        <span class="align-middle">{{
                          $t("lbl_upload_certificates")
                        }}</span>
                      </b-button>
                    </b-col>
                  </b-row>
                </template>
              </div>
            </b-form>
          </b-overlay>
        </validation-observer>
      </div>
    </b-card>

    <b-card no-body class="mb-0">
      <h5 class="border-left-primary pl-50 mb-1" style="border-left-width: 4px">
        {{ $t("lbl_the_certificates_uploaded") }}
      </h5>

      <b-table
        ref="refProductCertificatesTable"
        class="position-relative"
        responsive
        primary-key="id"
        show-empty
        hover
        :busy="showTable"
        :empty-text="$t('lbl_no_matching_records_found')"
        :items="certi_doc"
        :fields="tableColumns"
      >
        <template #cell(count)="scope">
          <span>{{ scope.index + 1 }}</span>
        </template>
        <template #cell(url)="scope">
          <div class="text-center" v-if="isImage(scope.item.url)">
            <img
              class="border rounded-lg"
              :src="scope.item.url"
              :alt="scope.item.name"
              :height="48"
            />
          </div>
          <div class="text-center" v-else>
            <feather-icon class="text-primary" size="48" icon="FileTextIcon" />
          </div>
        </template>
        <template #cell(verify_state)>
          <div
            class="d-flex align-items-center justify-content-center text-nowrap"
            :set="(state = getVerifyState(productDetail))"
          >
            <span
              v-b-tooltip.hover
              :title="state.label"
              :class="state.classes"
              class="d-flex text-sm border rounded-circle"
              :style="{
                width: `24px`,
                height: `24px`,
              }"
            >
              <feather-icon class="m-auto" :icon="state.icon" />
            </span>
          </div>
        </template>
        <template #cell(action)="scope">
          <div class="text-right text-nowrap" @click.stop>
            <b-button
              v-b-tooltip.hover.top
              variant="outline-primary"
              size="sm"
              :title="$t('lbl_view_file')"
              @click="openLink(scope.item.url)"
            >
              <feather-icon size="16" icon="EyeIcon" />
            </b-button>
            <b-button
              v-if="
                scope.item.verify_state <= PRODUCT_CERTIFICATES_STATES.WAITING
              "
              v-b-tooltip.hover.top
              v-b-modal.hodo-modal-upload-file
              variant="outline-primary ml-1"
              size="sm"
              :title="$t('Edit')"
              @click="handleGetRecord(scope.item)"
            >
              <feather-icon size="16" icon="EditIcon" />
            </b-button>
            <b-button
              v-if="
                scope.item.verify_state === PRODUCT_CERTIFICATES_STATES.DRAFF
              "
              v-b-tooltip.hover.top
              variant="outline-danger ml-1"
              size="sm"
              :title="$t('lbl_delete_file')"
              @click="deleteFile(scope.index, certi_doc)"
            >
              <feather-icon size="16" icon="Trash2Icon" />
            </b-button>
          </div>
        </template>
      </b-table>
    </b-card>

    <b-modal
      id="hodo-modal-upload-file"
      scrollable
      centered
      :title="recordDetail.name"
      :cancel-title="$t('lbl_cancel')"
      :ok-title="$t('lbl_update')"
      cancel-variant="outline-secondary"
      @ok="handleOk"
    >
      <div>
        <validation-observer ref="editCertificateValidation">
          <b-form>
            <b-form-group class="mb-2 required">
              <validation-provider
                #default="{ errors }"
                :name="recordDetail.name"
                rules="required"
              >
                <b-form-file
                  :ref="`refEditInputCertificateEl${recordDetail.id}`"
                  v-model="recordDetail.file"
                  :placeholder="$t('lbl_choose_file')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-form>
        </validation-observer>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { PRODUCT_CERTIFICATES_STATES, PRODUCT_ON_HODO } from "@/utils/constant";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { required } from "@validations";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import Ripple from "vue-ripple-directive";
import useProductCertificate from "./useProductCertificate";

export default {
  name: "ProductCertificates",
  props: {
    is_changed_category: Number,
    categorySelected: {
      type: Object,
    },
    certificatesData: {
      type: Array,
    },
    productDetail: Object,
    disable: Boolean,
  },
  components: {
    ToastificationContent,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },

  data() {
    return {
      required,
      isLoading: false,
      certificatesForm: this.categorySelected?.product_certi || null,
      certificates: [],
      certi_doc: [],
      PRODUCT_CERTIFICATES_STATES,
      recordDetail: {},
    };
  },
  beforeMount() {
    // console.log(this.certificatesData);
  },
  watch: {
    is_changed_category: {
      handler(value) {
        if (value === 1) {
          this.certi_doc = [];
        } else {
          this.certi_doc =
            (this.certificatesData && [...this.certificatesData]) || [];
        }
      },
    },
    categorySelected: {
      handler(data) {
        this.certificatesForm =
          typeof data !== "undefined" && Object.keys(data).length
            ? [...Object.values(data?.product_certi || [])].map((item) => {
                return {
                  ...item,
                  file: null,
                };
              })
            : [];
      },
      deep: true,
    },
    certificatesData: {
      handler(data) {
        this.certi_doc = (data && [...data]) || [];
      },
      deep: true,
    },
  },
  setup() {
    const mixData = useProductCertificate();
    return { ...mixData };
  },
  methods: {
    refreshTable() {
      this.showTable = true;
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.refetchData();
        this.showTable = false;
      }, 500);
    },
    resetForm() {
      requestAnimationFrame(() => {
        this.$refs?.uploadCertificatesValidation?.reset();
      });

      this.certificatesForm = Object.keys(this.categorySelected).length
        ? [...this.categorySelected?.product_certi].map((item) => {
            return {
              ...item,
              file: null,
            };
          })
        : [];
      this.isLoading = false;
    },
    async uploadFiles(file, data) {
      const params = {
        file,
        FolderTarget: `certificates`,
        IdTarget: data.id,
      };
      const formData = new FormData();

      for (const key in params) {
        formData.append(key, params[key]);
      }

      const res = await this.$rf
        .getRequest("CommonRequest")
        .uploadFileTenant(formData);
      return {
        id: data.id,
        url: res.data.fileUrl,
        name: data.name,
      };
    },
    handleUploadCertificate() {
      try {
        this.$refs.uploadCertificatesValidation
          .validate()
          .then(async (success) => {
            if (success) {
              this.isLoading = true;

              const files = this.certificatesForm?.map((item) => {
                return {
                  id: item.id,
                  file: item.file,
                  name: item.name,
                };
              });
              const filesRaw = await Promise.all(
                files.map(async (f) => {
                  if (!(f.file instanceof File)) {
                    return f.file;
                  }

                  const url = await this.uploadFiles(f.file, f);
                  return url;
                })
              );
              this.certificates = filesRaw?.map((item) => {
                return {
                  ...item,
                  verify_state: 0,
                };
              });
              this.certi_doc = [...this.certi_doc, ...this.certificates];

              this.$emit("setCertificates", filesRaw);
              this.resetForm();
            }
          });
      } catch (error) {
        console.log(error);
        this.isLoading = false;
      }
    },
    handleGetRecord(record) {
      this.recordDetail = { ...record };
    },
    handleEditSubmit() {
      this.$refs.editCertificateValidation.validate().then(async (success) => {
        if (success) {
          const { file, ...data } = this.recordDetail;
          const rawData = await this.uploadFiles(file, data);

          if (
            this.certificatesData?.length &&
            rawData?.url &&
            data?.id &&
            this.is_changed_category !== 1
          ) {
            this.$rf
              .getRequest("CommonRequest")
              .updateProductCertificateItem(data?.id, {
                ...data,
                url: rawData?.url,
              })
              .then((res) => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: this.$t("lbl_updated_successfully"),
                    icon: "CheckIcon",
                    variant: "success",
                  },
                });
                this.$emit("refresh");
              });
          } else {
            const tempData = [...this.certi_doc];
            const foundItem = tempData?.find((item) => item.id === data.id);

            if (typeof foundItem !== "undefined") {
              const newData = tempData.map((item) => {
                if (item?.id === foundItem?.id) {
                  return {
                    ...item,
                    url: rawData?.url,
                  };
                }

                return { ...item };
              });

              this.certi_doc = [...newData];
              this.$emit("setCertificates", this.certi_doc);
            }
          }

          this.refreshTable();
          this.$nextTick(() => {
            this.$bvModal.hide("hodo-modal-upload-file");
          });
        }
      });
    },
    handleOk(bvEvent) {
      bvEvent.preventDefault();
      this.handleEditSubmit();
    },
    openLink(fileUrl) {
      if (!fileUrl) return;
      window.open(fileUrl, "_blank", "noreferrer");
    },
    deleteFile(index, record) {
      record.splice(index, 1);
    },
    getVerifyState(state) {
      const status = this.productDetail?.status;
      // switch (state) {
      //   case PRODUCT_CERTIFICATES_STATES.DRAFF:
      //     return {
      //       classes: "bg-warning text-white",
      //       icon: "AlertTriangleIcon"
      //     };
      //   case PRODUCT_CERTIFICATES_STATES.WAITING:
      //     return {
      //       classes: "bg-primary text-white",
      //       icon: "MoreHorizontalIcon"
      //     };
      //   case PRODUCT_CERTIFICATES_STATES.VERIFIED:
      //     return {
      //       classes: "bg-success text-white",
      //       icon: "CheckIcon"
      //     };
      //   case PRODUCT_CERTIFICATES_STATES.REJECTED:
      //     return {
      //       classes: "bg-danger text-white",
      //       icon: "XIcon"
      //     };

      //   default:
      //     return {
      //       classes: "",
      //       icon: ""
      //     };
      // }
      switch (status) {
        case PRODUCT_ON_HODO.UNAVAILABLE:
        case PRODUCT_ON_HODO.PENDING_AVAILABLE:
          return {
            classes: "bg-primary text-white",
            icon: "MoreHorizontalIcon",
            label: this.$t("Đợi duyệt"),
          };
        case PRODUCT_ON_HODO.AVAILABLE:
          return {
            classes: "bg-success text-white",
            icon: "CheckIcon",
            label: this.$t("Đang hoạt động"),
          };
        case PRODUCT_ON_HODO.REJECT_AVAILABLE:
          return {
            classes: "bg-danger text-white",
            icon: "XIcon",
            label: this.$t("Bị từ chối"),
          };

        default:
          return {
            classes: "bg-primary text-white",
            icon: "MoreHorizontalIcon",
            label: this.$t("Đợi duyệt"),
          };
      }
    },
    isImage(file) {
      if (!file) return false;
      if (file?.url && /(getimagefile)/.test(file)) {
        return true;
      }

      if (file instanceof File) {
        return !!file.type.match("image.*");
      }

      const extensions = ["jpg", "jpeg", "png", "tiff", "bmp", "svg"];
      const extVal = file?.split(".").pop().toLowerCase();

      return extensions.includes(extVal);
    },
    getExtensionOfFile(filename) {
      if (!filename) return;
      return filename?.split(".").pop().toLowerCase();
    },
  },
};
</script>

<style lang="scss" scoped>
.disable {
  cursor: not-allowed;
  pointer-events: none;

  .btn {
    opacity: 60%;
  }
}
</style>
