import { PRODUCT_ON_HODO, PRODUCT_TYPES } from "@/utils/constant"
import { ref, watch } from "@vue/composition-api"

import { $themeConfig } from "@themeConfig"
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue"
import Vue from "vue"
import appUtils from "@/utils/appUtils"
import i18n from "@/libs/i18n"
import router from "@/router"
import store from "@/store"
import { useInputImageRenderer } from "@/@core/comp-functions/forms/form-utils"
import { useToast } from "vue-toastification/composition"

export default function useAddProduct() {
  const toast = useToast()
  const initialForm = ref({
    name: "",
    registration_number: null,
    sku: "",
    slug: "",
    brand_id: 0,
    dosage_form_id: 0,
    specification_id: 0,
    manufacturer_id: 0,
    origin_id: 0,
    categories_id: [],
    ingredient: "",
    short_desc: "",
    description: "",
    indication: "",
    contraindicated: "",
    notes: "",
    is_prescription_required: false,
    is_medicine: false,
    // brand_id: 0,
    primary_image: null,
    thumb_images: [],
    thumbnail_image: [],
    cate_id: null,
    product_variants: [
      {
        code: appUtils.randomCode(10),
        core_unit_id: 0,
        price: null,
        unit_id: null,
        conversion_rate: 1,
        default: 2,
        is_default: 2,
        unit: {
          price: null,
          unit_id: null,
        },
      },
    ],
    product_multilanguage: [
      {
        description: "",
        language: "en",
        manufacturer: "",
        name: "",
        origin: "",
        short_desc: "",
      },
    ],
    type: 1,
    default_price: 0,
    default_unit_name: "",
    default_number_unit: 0,
    deliverable_status_id: 0,
    deliverable_definition_id: 0,
    certi_doc: [],
    manufacturer: "",
    origin: "",
    other_expenses: [
      {
        code: appUtils.randomCode(10),
        value: null,
        unit: "VNĐ",
        name: "",
      },
    ],
    view_web: true,
  })
  const initialDeliverables = ref({
    deliverableDocs: [
      {
        code: appUtils.randomCode(10),
        title: "",
      },
    ],
    deliverableStatus: [
      {
        code: appUtils.randomCode(10),
        title: "",
      },
    ],
    attachementItems: [
      {
        code: appUtils.randomCode(10),
        name: "",
      },
    ],
  })
  const deliverableDocs = ref([...initialDeliverables.value.deliverableDocs])
  const deliverableStatus = ref([
    ...initialDeliverables.value.deliverableStatus,
  ])
  const attachementItems = ref([...initialDeliverables.value.attachementItems])
  const form = ref(initialForm.value)
  const domain = ref($themeConfig.app.domain)
  const optionsEditor = ref({
    placeholder: "",
    theme: "snow",
  })
  const isSaleSelected = ref(false)
  const isProductTypeService = ref(false)
  const isPublished = ref(true)
  const isRequestToVerify = ref(true)
  const unitsOption = ref([])
  const unitSelected = ref(null)
  const categoriesOption = ref([])
  const categorySelected = ref(null)
  const brandsOption = ref([])
  const categoryOption = ref([])
  const originsOption = ref([])
  const dosageFormsOption = ref([])
  const specificationsOption = ref([])
  const manufacturersOption = ref([])
  const brandSelected = ref(null)
  const deliveryStatusOptions = ref([])
  const deliveryStatusSelected = ref(null)
  const deliveryDefinitionOptions = ref([])
  const deliveryDefinitionSelected = ref(null)
  const refInputEl = ref(null)
  const refInputThumbEl = ref(null)
  const previewImg = ref(null)
  const thumbsImg = ref([])
  const addProductValidation = ref(null)
  const productId = ref(null)
  const productDetail = ref(null)
  const certificates = ref(null)
  const certificatesData = ref(null)
  const attachmentConfigSelected = ref(null)
  const attachmentConfigs = ref([])
  const isRequiredAttachment = ref(false)
  const definitionTitleConfig = ref("")
  const attachMentTitleConfig = ref("")
  const is_changed_category = ref(2)
  const isLoading = ref(false)

  const statusTitleConfig = ref("")
  const current_status = ref(null)
  const formRegisterProfile = ref([])

  const userData = appUtils.getLocalUser()
  const default_params = {
    page_num: 1,
    page_size: 1000,
    supplier_id: userData?.supplierInfo?.id,
    sort_by: "",
    keyword: "",
    created_at: "",
    order: "desc",
  }

  watch([unitSelected], () => {
    form.value.unit_id = unitSelected.value?.id
  })
  watch([categorySelected], () => {
    form.value.cate_id = categorySelected.value?.id

    if (productDetail.value?.id) {
      is_changed_category.value =
        categorySelected.value?.id !== productDetail.value?.cate_id ? 1 : 2
      form.value.category = categorySelected.value
    }
  })
  watch([brandSelected], () => {
    form.value.brand_id = brandSelected.value?.id
  })
  watch([deliveryStatusSelected], () => {
    form.value.deliverable_status_id = deliveryStatusSelected.value?.id
  })
  watch([deliveryDefinitionSelected], () => {
    form.value.deliverable_definition_id = deliveryDefinitionSelected.value?.id
  })
  watch([attachmentConfigSelected], () => {
    form.value.order_form_config_id = attachmentConfigSelected.value?.id
  })
  watch([isRequestToVerify], () => {
    form.value.status = isRequestToVerify.value ? 2 : 1
  })
  watch([certificates], () => {
    form.value.certi_doc =
      (certificates.value && [...certificates.value]) || []
  })
  watch([isRequiredAttachment], () => {
    if (!isRequiredAttachment.value) {
      attachmentConfigSelected.value = null
    }
  })

  const fetchCategoriesList = (ctx, callback) => {
    store
      .dispatch("categories/fetchCategoriesList", {
        page_size: 1000,
        page_num: 1,
      })
      .then((response) => {
        categoriesOption.value = response.data.data.map((elm) => {
          return {
            ...elm,
            name_en: elm.product_category_multilanguages?.find(
              (item) => item.language === "en"
            )?.name,
          }
        })
      })
      .catch((e) => {
        console.log(e)
        toast({
          component: ToastificationContent,
          props: {
            title: i18n.t("lbl_error_fetching_categories_data"),
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        })
      })
  }

  const fetchUnitsList = (ctx, callback) => {
    store
      .dispatch("categories/fetchUnitsList", {
        page_num: 1,
        page_size: 1000,
        supplier_id: userData?.supplierInfo?.id,
      })
      .then((response) => {
        unitsOption.value = response.data.data.map((elm) => {
          return {
            ...elm,
            name_en: elm.product_unit_multilanguages?.find(
              (item) => item.language === "en"
            )?.name,
          }
        })
      })
      .catch((e) => {
        console.log(e)
        toast({
          component: ToastificationContent,
          props: {
            title: i18n.t("lbl_error_fetching_units_data"),
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        })
      })
  }

  const fetchDosageFormList = async () => {
    return Vue.prototype.$rf
      .getRequest("ProductRequest")
      .getDosageForms(default_params)
      .then((response) => {
        dosageFormsOption.value = response.data.data
      })
      .catch((e) => {
        console.log(e)
        // toast({
        //   component: ToastificationContent,
        //   props: {
        //     title: i18n.t("lbl_error_fetching_dosage_forms_data"),
        //     icon: "AlertTriangleIcon",
        //     variant: "danger",
        //   },
        // })
        return
      })
  }

  const fetchOriginList = async () => {
    return Vue.prototype.$rf
      .getRequest("ProductRequest")
      .getOrigins(default_params)
      .then((response) => {
        originsOption.value = response.data.data
      })
      .catch((e) => {
        console.log(e)
        toast({
          component: ToastificationContent,
          props: {
            title: i18n.t("lbl_error_fetching_origins_data"),
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        })
        return
      })
  }

  const fetchCategoryList = async () => {
    return Vue.prototype.$rf
      .getRequest("ProductRequest")
      .getCategories(default_params)
      .then((response) => {
        categoryOption.value = response.data.data
        return response
      })
      .catch((e) => {
        console.log(e)
        toast({
          component: ToastificationContent,
          props: {
            title: i18n.t("lbl_error_fetching_categories_data"),
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        })
        return
      })
  }

  const fetchSpecificationList = async () => {
    return Vue.prototype.$rf
      .getRequest("ProductRequest")
      .getSpecifications(default_params)
      .then((response) => {
        specificationsOption.value = response.data.data
      })
      .catch((e) => {
        console.log(e)
        toast({
          component: ToastificationContent,
          props: {
            title: i18n.t("lbl_error_fetching_specifications_data"),
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        })
        return
      })
  }

  const fetchUnitList = async () => {
    return Vue.prototype.$rf
      .getRequest("ProductRequest")
      .getUnits(default_params)
      .then((response) => {
        unitsOption.value = response.data.data
      })
      .catch((e) => {
        console.log(e)
        toast({
          component: ToastificationContent,
          props: {
            title: i18n.t("lbl_error_fetching_units_data"),
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        })
        return
      })
  }

  const fetchManufacturerList = async () => {
    return Vue.prototype.$rf
      .getRequest("ProductRequest")
      .getManufacturers(default_params)
      .then((response) => {
        manufacturersOption.value = response.data.data
      })
      .catch((e) => {
        console.log(e)
        toast({
          component: ToastificationContent,
          props: {
            title: i18n.t("lbl_error_fetching_manufacturers_data"),
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        })
        return
      })
  }

  const fetchBrandList = async () => {
    return Vue.prototype.$rf
      .getRequest("ProductRequest")
      .getBrands(default_params)
      .then((response) => {
        brandsOption.value = response.data.data
      })
      .catch((e) => {
        console.log(e)
        toast({
          component: ToastificationContent,
          props: {
            title: i18n.t("lbl_error_fetching_brands_data"),
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        })
        return
      })
  }

  const fetchBrandsList = (ctx, callback) => {
    store
      .dispatch("configProductPartner/fetchBrand", {
        page_num: 1,
        page_size: 1000,
        supplier_id: userData?.supplierInfo?.id,
      })
      .then((response) => {
        brandsOption.value = response.data.data
      })
      .catch((e) => {
        console.log(e)
        toast({
          component: ToastificationContent,
          props: {
            title: i18n.t("lbl_error_fetching_brands_data"),
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        })
      })
  }

  const fetchDeliveryDefinitionConfigsList = () => {
    const supplier_id = store.state.authenticate?.userLogin?.supplierInfo?.id
    store
      .dispatch("categories/fetchDeliveryDefinitionConfigsList", {
        supplier_id,
      })
      .then((response) => {
        deliveryDefinitionOptions.value = response.data.data.map((elm) => {
          return {
            ...elm,
            title_en:
              elm.product_delivery_attachments_config_multilanguage?.find(
                (item) => item.language === "en"
              )?.title || elm.title,
          }
        })
        deliveryDefinitionOptions.value.push({
          id: -1,
          title: i18n.t("lbl_add_new_config"),
          attachment_items: [],
        })
      })
      .catch((e) => {
        console.log(e)
        toast({
          component: ToastificationContent,
          props: {
            title: i18n.t(
              "lbl_error_fetching_delivery_definition_configs_data"
            ),
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        })
      })
  }
  const getAttachmentConfigs = (supplier_id) => {
    if (!supplier_id) return
    store
      .dispatch("products/getOrderFormConfigs", {
        supplier_id,
      })
      .then((response) => {
        attachmentConfigs.value = response?.data?.data || []
        // attachmentConfigs.value.push({
        //   id: -1,
        //   title: i18n.t("lbl_add_new_config"),
        //   order_form_items: [],
        // });
      })
      .catch((e) => {
        console.log(e)
      })
  }

  const fetchDeliveryStatusConfigsList = (ctx, callback) => {
    const supplier_id = store.state.authenticate?.userLogin?.supplierInfo?.id
    store
      .dispatch("categories/fetchDeliveryStatusConfigsList", { supplier_id })
      .then((response) => {
        deliveryStatusOptions.value = response.data.data?.map((elm) => {
          return {
            ...elm,
            title_en:
              elm?.deliverable_status_multilanguage?.find(
                (item) => item.language === "en"
              )?.title || elm.title,
          }
        })
        deliveryStatusOptions.value.push({
          id: -1,
          title: i18n.t("lbl_add_new_config"),
          delivery_state_steps: [],
        })
      })
      .catch((e) => {
        console.log(e)
        toast({
          component: ToastificationContent,
          props: {
            title: i18n.t("lbl_error_fetching_delivery_status_configs_data"),
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        })
      })
  }

  const fetchProductCertificates = (ctx, callback) => {
    store
      .dispatch("products/fetchProductCertificates", {
        product_id: productId.value,
      })
      .then((response) => {
        certificatesData.value = response.data.data.data
      })
      .catch((e) => {
        console.log(e)
        toast({
          component: ToastificationContent,
          props: {
            title: i18n.t("lbl_error_fetching_product_certificates_data"),
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        })
      })
  }

  const fetchProductById = async (ctx, callback) => {
    await store
      .dispatch("products/fetchProductById", { id: productId.value })
      .then((response) => {
        const product = response.data
        const variants = product?.product_variants?.map((v) => {
          return {
            ...v,
            unit: {
              id: v.unit_id,
              name: v.unit_name,
              name_en: unitsOption.value
                ?.find((elm) => elm.id === v.unit_id)
                ?.product_unit_multilanguages?.find(
                  (item) => item.language === "en"
                )?.name,
            },
          }
        })
        productDetail.value = { ...product }
        form.value = {
          ...product,
          product_multilanguage: product?.product_multilanguages?.filter(
            (elm) => elm.language === "en"
          ) || [
              {
                name: "",
                short_desc: "",
                description: "",
                origin: "",
                manufacturer: "",
                language: "en",
              },
            ],
          brand: product?.brand?.name || "",
          product_variants: variants,
          other_expenses: product?.other_expenses || [
            {
              code: appUtils.randomCode(10),
              value: null,
              unit: "VNĐ",
              name: "",
            },
          ],
          is_medicine: product?.physical_product_type === 1,
          is_prescription_required: product?.is_prescription_required === 2,
        }
        const categoryRaw = categoriesOption.value?.find(
          (cate) => cate.id === product?.cate_id
        )
        categorySelected.value = categoryRaw
          ? { ...product?.category, ...categoryRaw }
          : { ...product?.category }
        brandSelected.value = { ...product?.brand }

        const deliveryStatusRaw = deliveryStatusOptions.value?.find(
          (item) => item.id === product?.deliverable_status_id
        )
        if (deliveryStatusRaw) {
          deliveryStatusSelected.value = { ...deliveryStatusRaw }
        }
        const deliveryDefinitionRaw = deliveryDefinitionOptions.value?.find(
          (item) => item.id === product?.deliverable_definition_id
        )
        if (deliveryDefinitionRaw) {
          deliveryDefinitionSelected.value = { ...deliveryDefinitionRaw }
        }

        previewImg.value = product?.primary_image
        thumbsImg.value =
          (product?.thumbnail_image && [...product?.thumbnail_image]) || []

        const attachmentConfigsRaw = attachmentConfigs.value?.find(
          (item) => item.id === product?.order_form_config_id
        )
        if (attachmentConfigsRaw) {
          isRequiredAttachment.value = true
          attachmentConfigSelected.value = { ...attachmentConfigsRaw }
        }

        isRequestToVerify.value = product?.status > PRODUCT_ON_HODO.UNAVAILABLE
        current_status.value = product?.status

        formRegisterProfile.value = formRegisterProfile.value.map((mapItem) => {
          let resultItem = {
            ...mapItem,
            checked: false,
          }
          if (resultItem.child && resultItem.child.length) {
            resultItem.child = resultItem.child.map((child) => {
              return {
                ...child,
                checked: false,
              }
            })
          }
          if (!product?.partner_product_consent_forms?.length)
            return resultItem

          resultItem.child = resultItem.child?.map((childItem) => {
            return {
              ...childItem,
              checked: false,
            }
          })

          product?.partner_product_consent_forms?.forEach((item) => {
            if (resultItem?.id === item?.consentform_id) {
              resultItem = {
                ...resultItem,
                ...item,
                id: resultItem.id,
                checked: true,
                isRequired: item?.is_required === 2,
              }
            }

            if (resultItem?.child?.length) {
              const resultIndex = resultItem.child?.findIndex(
                (childItem) => childItem?.id === item?.consentform_id
              )
              if (!resultItem.child[resultIndex]) return

              resultItem.child[resultIndex] = {
                ...resultItem.child[resultIndex],
                ...item,
                id: resultItem.child[resultIndex].id,
                checked: true,
                isRequired: item?.is_required === 2,
              }
            }
          })
          return resultItem
        })
      })
      .catch((e) => {
        console.log(e)
        toast({
          component: ToastificationContent,
          props: {
            title: i18n.t("lbl_error_fetching_product_detail_data"),
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        })
      })
  }

  const validateSelectedImage = (inputEl) => {
    if (!inputEl.value?.files[0] || inputEl.value?.files === null) {
      toast({
        component: ToastificationContent,
        props: {
          title: i18n.t("lbl_please_select_a_image_file"),
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      })
      inputEl.value = null
      return false
    }

    const fileSize = inputEl.value.files[0]?.size / 1024 / 1024

    if (fileSize > 2) {
      toast({
        component: ToastificationContent,
        props: {
          title: i18n.t("lbl_image_file_size_exceeds_2_mb"),
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      })
      inputEl.value = null
      return false
    }
    return true
  }

  const handleSelectPreviewImage = (e) => {
    if (!validateSelectedImage(refInputEl)) {
      form.value.primary_image = null
      previewImg.value = null
      return
    }

    form.value.primary_image = refInputEl.value.files[0]

    const { inputImageRenderer } = useInputImageRenderer(
      refInputEl,
      (base64) => {
        previewImg.value = base64
      }
    )
    inputImageRenderer()
  }

  const handleSelectThumbsImage = () => {
    if (!validateSelectedImage(refInputThumbEl)) return

    form.value.thumb_images = [...refInputThumbEl.value.files]

    for (let i = 0; i < refInputThumbEl.value.files?.length; i++) {
      const file = refInputThumbEl.value.files[i]
      const reader = new FileReader()

      reader.addEventListener(
        "load",
        () => {
          thumbsImg.value.push({
            url: reader.result,
          })
        },
        false
      )

      if (file) {
        reader.readAsDataURL(file)
      }
    }
  }

  const generateSlug = () => {
    if (!form.value.name) {
      return
    }
    form.value.slug = appUtils.slugifyStr(form.value.name)
  }

  const priceFormat = ({ key, value }) => {
    const newPrice = appUtils.numberFormat(value)
    Vue.nextTick(() => (form.value[key] = newPrice))
  }

  const uploadFiles = async (file, product) => {
    const params = {
      file,
      FolderTarget: `products`,
      IdTarget: product?.id,
    }
    const formData = new FormData()

    for (const key in params) {
      formData.append(key, params[key])
    }

    const res = await Vue.prototype.$rf
      .getRequest("CommonRequest")
      .uploadFileTenant(formData)
    return res.data.fileUrl
  }
  const uploadFilesAttachment = async (file) => {
    const params = {
      file,
      FolderTarget: `attachment`,
      IdTarget: 0,
    }
    const formData = new FormData()

    for (const key in params) {
      formData.append(key, params[key])
    }

    const res = await Vue.prototype.$rf
      .getRequest("CommonRequest")
      .uploadFileTenant(formData)
    return res.data.fileUrl
  }

  // const createAttachmentConfig = async () => {
  //   if (attachmentConfigSelected.value?.id !== -1) return;
  //   const attachement_items = [];
  //   attachementItems.value.forEach(x, (index) => {
  //     const urlImage = uploadFilesAttachment(file);
  //     attachement_items.push({});
  //   });
  // };

  const createDefinitionConfig = async () => {
    if (deliveryDefinitionSelected.value?.id !== -1) return

    const definition_items = deliverableDocs.value
    const params = {
      title: definitionTitleConfig.value,
      supplier_id: userData?.supplierInfo?.id,
      definition_items,
    }
    const res = await Vue.prototype.$rf
      .getRequest("CommonRequest")
      .createNewDefinitionConfigs(params)
    return res.data
  }

  const createStatusConfig = async () => {
    if (deliveryStatusSelected.value?.id !== -1) return

    const delivery_items = deliverableStatus.value?.map((item, index) => {
      return {
        ...item,
        order_number: index + 1,
      }
    })
    const params = {
      title: statusTitleConfig.value,
      supplier_id: userData?.supplierInfo?.id,
      delivery_items,
    }
    const res = await Vue.prototype.$rf
      .getRequest("CommonRequest")
      .createNewDeliveryStatusConfig(params)
    return res.data
  }

  const addProduct = () => {
    addProductValidation.value.validate().then(async (success) => {
      if (success) {
        isLoading.value = true

        let default_price = null
        let default_unit_name = ""
        let default_number_unit = 0
        let core_unit_id = 0

        const productVariant = form.value.product_variants?.find(
          (item) => item?.is_default === 2
        )
        const minimumProdUnit = form.value.product_variants.reduce(
          (min, p) => (p.conversion_rate < min.conversion_rate ? p : min),
          form.value.product_variants[0]
        )

        core_unit_id =
          productVariant?.core_unit_id || minimumProdUnit?.unit_id || 0
        default_price =
          Number(productVariant?.price?.toString()?.replaceAll(",", "")) || 0
        default_unit_name = productVariant?.unit?.name || ""

        const {
          primary_image,
          thumb_images,
          product_variants,
          is_medicine,
          other_expenses,
          is_prescription_required,
          ...newForm
        } = form.value

        const variantsRaw = product_variants?.map((v) => {
          console.log("conversion_rate", v?.conversion_rate)
          return {
            ...v,
            core_unit_id,
            package_name: v.unit?.name,
            unit_name: v.unit?.name,
            number_unit: v?.number_unit || 0,
            price: Number(v?.price?.toString()?.replaceAll(",", "")) || 0,
            conversion_rate: Number(v?.conversion_rate) || 0,
          }
        })
        const otherExpensesRaw = other_expenses
          ?.map((item) => {
            return {
              ...item,
              value: Number(item?.value?.toString()?.replaceAll(",", "")) || 0,
            }
          })
          ?.filter((item) => item.name && item.value)
        // const definitionData = await createDefinitionConfig();
        const deliveryStatusData = await createStatusConfig()

        const consentForm =
          form.value.type === PRODUCT_TYPES.SERVICE
            ? formRegisterProfile.value
              ?.filter(
                (item) =>
                  item.checked ||
                  item?.child?.some((childItem) => childItem.checked)
              )
              .reduce((arr, item) => {
                if (item?.child?.length) {
                  item?.child?.forEach((childItem) => {
                    if (childItem.checked) {
                      arr.push({
                        id: childItem?.id,
                        required: childItem?.isRequired ? 2 : 1,
                      })
                    }
                  })
                } else {
                  arr.push({
                    id: item?.id,
                    required: item?.isRequired ? 2 : 1,
                  })
                }
                return arr
              }, [])
            : null

        const params = {
          ...newForm,
          primary_image: "",
          core_unit_id,
          default_price,
          default_unit_name,
          default_number_unit,
          supplier_id: userData?.supplierInfo?.id,
          product_variants: variantsRaw,
          // deliverable_definition_id:
          //   definitionData?.ID || newForm.deliverable_definition_id,
          deliverable_status_id:
            deliveryStatusData?.ID || newForm.deliverable_status_id,
          deliverable_docs: deliverableDocs.value,
          deliverable_status: deliverableStatus.value,
          order_form_config_id: attachmentConfigSelected.value?.id,
          is_require_form: isRequiredAttachment.value ? 1 : 0,
          status: isRequestToVerify.value ? 2 : 1,
          other_expenses: otherExpensesRaw?.length ? otherExpensesRaw : null,
          consent_form: consentForm,
          physical_product_type: is_medicine ? 1 : 2,
          is_prescription_required: is_prescription_required ? 2 : 1,
        }

        console.log("params :", params)

        store
          .dispatch("products/addProduct", params)
          .then(async (response) => {
            const product = response.data
            const files = [
              form.value.primary_image,
              ...form.value.thumb_images,
            ]
            const filesRaw = await Promise.all(
              files.map(async (file) => {
                if (!(file instanceof File)) {
                  return file
                }

                const url = await uploadFiles(file, product)
                return url
              })
            )
            const primary_image = filesRaw[0]
            const imagesRaw = filesRaw.slice(1)
            const thumbnail_image = imagesRaw.map((img) => {
              return {
                url: img,
              }
            })

            store
              .dispatch("products/updateProduct", {
                id: product?.id,
                data: {
                  primary_image,
                  thumbnail_image,
                  supplier_id:
                    product?.supplier_id || userData?.supplierInfo?.id,
                  type: product?.type,
                },
              })
              .then((res) => {
                toast({
                  component: ToastificationContent,
                  props: {
                    title: i18n.t("lbl_add_new_product_success"),
                    icon: "CheckIcon",
                    variant: "success",
                  },
                })
                productId.value = res.data?.id
                productDetail.value = res.data
                router
                  .push({
                    // path: `/products/${res.data?.slug}-${res.data?.id}`,
                    name:
                      productDetail.value.type === PRODUCT_TYPES.PRODUCT
                        ? "products"
                        : "services",
                  })
                  .catch((_) => { })
              })
          })
          .catch((e) => {
            console.log(e)
            toast({
              component: ToastificationContent,
              props: {
                title: i18n.t("lbl_error_add_new_product"),
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            })
          })
          .finally(() => {
            setTimeout(() => {
              isLoading.value = false
            }, 2000)
          })
      }
    })
  }

  const updateProduct = () => {
    if (!productId || !productDetail) {
      toast({
        component: ToastificationContent,
        props: {
          title: i18n.t("lbl_error_does_not_get_product_detail_data"),
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      })
      return
    }

    addProductValidation.value.validate().then(async (success) => {
      if (success) {
        isLoading.value = true

        let default_price = null
        let default_unit_name = ""
        let core_unit_id = 0

        const productVariant = form.value.product_variants?.find(
          (item) => item?.is_default === 2
        )
        const minimumProdUnit = form.value.product_variants.reduce(
          (min, p) => (p.conversion_rate < min.conversion_rate ? p : min),
          form.value.product_variants[0]
        )

        core_unit_id =
          productVariant?.core_unit_id || minimumProdUnit?.unit_id || 0
        default_price =
          Number(productVariant?.price?.toString()?.replaceAll(",", "")) || 0
        default_unit_name = productVariant?.unit_name || ""

        const thumbs =
          (form.value?.thumbnail_image &&
            form.value?.thumbnail_image?.map((img) => img.url)) ||
          []
        const thumbImagesRaw = form.value.thumb_images || []

        const files = [form.value.primary_image, ...thumbs, ...thumbImagesRaw]
        const filesRaw = await Promise.all(
          files.map(async (file) => {
            if (!(file instanceof File)) {
              return file
            }

            const url = await uploadFiles(file, productDetail.value)
            return url
          })
        )
        const primary_image = filesRaw[0]
        const thumb_images_raw = filesRaw.slice(1)
        const thumbnail_image = thumb_images_raw?.map((img) => {
          return {
            url: img,
          }
        })
        const variants = form.value.product_variants?.map((v) => {
          console.log("conversion_rate", v?.conversion_rate)


          return {
            ...v,
            // number_unit: "",
            package_name: v.unit?.name,
            unit_name: v.unit?.name,
            number_unit: v.number_unit,
            price: Number(v?.price?.toString()?.replaceAll(",", "")) || 0,
            conversion_rate: Number(v?.conversion_rate) || 0,
          }
        })

        const otherExpensesRaw = form.value.other_expenses?.map((item) => {
          return {
            ...item,
            value: Number(item?.value?.toString()?.replaceAll(",", "")) || 0,
          }
        })

        // const definitionData = await createDefinitionConfig()
        const deliveryStatusData = await createStatusConfig()

        const consentForm = formRegisterProfile.value
          ?.filter(
            (item) =>
              item.checked ||
              item?.child?.some((childItem) => childItem.checked)
          )
          .reduce((arr, item) => {
            if (item?.child?.length) {
              item?.child?.forEach((childItem) => {
                if (childItem.checked) {
                  arr.push({
                    id: childItem?.id,
                    required: childItem?.isRequired ? 2 : 1,
                  })
                }
              })
            } else {
              arr.push({
                id: item?.id,
                required: item?.isRequired ? 2 : 1,
              })
            }
            return arr
          }, [])

        const { is_medicine, is_prescription_required, ...newForm } =
          form.value

        const params = {
          ...newForm,
          core_unit_id,
          default_price,
          default_unit_name,
          primary_image,
          thumbnail_image,
          current_status: current_status.value,
          supplier_id:
            productDetail.value.supplier_id || userData?.supplierInfo?.id,
          product_variants: variants,
          is_require_form: isRequiredAttachment.value ? 1 : 0,
          // deliverable_definition_id:
          //   definitionData?.ID || form.value.deliverable_definition_id,
          deliverable_status_id:
            deliveryStatusData?.ID || form.value.deliverable_status_id,
          is_changed_category: is_changed_category.value,
          other_expenses: otherExpensesRaw,
          consent_form: consentForm,
          physical_product_type: is_medicine ? 1 : 2,
          is_prescription_required: is_prescription_required ? 2 : 1,
        }

        console.log(params)

        store
          .dispatch("products/updateProduct", {
            id: productDetail.value.id,
            data: params,
          })
          .then((res) => {
            toast({
              component: ToastificationContent,
              props: {
                title: i18n.t("lbl_update_product_success"),
                icon: "CheckIcon",
                variant: "success",
              },
            })
            productDetail.value = res.data
            router
              .replace({
                path: `/${productDetail.value.type === PRODUCT_TYPES.PRODUCT
                  ? "products"
                  : "services"
                  }/${res.data?.slug}-${res.data?.id}`,
              })
              .catch((_) => { })
          })
          .catch((e) => {
            console.log(e)
            toast({
              component: ToastificationContent,
              props: {
                title: i18n.t("lbl_error_when_update_product"),
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            })
          })
          .finally(() => {
            setTimeout(() => {
              isLoading.value = false
            }, 2000)
          })
      }
    })
  }

  const handleChangeType = (type) => {
    if (type === PRODUCT_TYPES.PRODUCT) {
      form.value.deliverable_definition_id = null
      deliveryDefinitionSelected.value = null
    } else {
      const deliverable_definition_id =
        productDetail.value?.deliverable_definition_id
      form.value.deliverable_definition_id = deliverable_definition_id
      const deliveryDefinitionRaw = deliveryDefinitionOptions.value?.find(
        (item) => item.id === deliverable_definition_id
      )
      if (deliveryDefinitionRaw) {
        deliveryDefinitionSelected.value = { ...deliveryDefinitionRaw }
      }
    }
  }

  const handleGetConsentForm = async () => {
    const params = {
      supplier_id: userData?.supplierInfo?.id,
      page_num: 1,
      page_size: 1000,
    }

    try {
      const res = await Vue.prototype.$rf
        .getRequest("CommonRequest")
        .getConsentForm(params)

      formRegisterProfile.value = (res.data.data || []).map((item) => ({
        ...item,
        checked: true,
        isRequired: false,
        child: item?.childs?.[0]
          ? item?.childs.map((child) => ({
            ...child,
            checked: true,
            isRequired: false,
          }))
          : null,
      }))
    } catch (error) {
      console.log(error)
    }
  }

  const handleChangeIsMedicine = () => {
    form.value = {
      ...form.value,
      is_prescription_required: false,
      dosage_form_id: 0,
      indication: "",
      contraindicated: "",
      notes: "",
    }
  }

  return {
    domain,
    initialForm,
    form,
    attachementItems,
    initialDeliverables,
    deliverableDocs,
    deliverableStatus,
    optionsEditor,
    isSaleSelected,
    isProductTypeService,
    isPublished,
    isRequestToVerify,
    unitsOption,
    unitSelected,
    categoriesOption,
    categorySelected,
    brandsOption,
    brandSelected,
    deliveryStatusOptions,
    deliveryStatusSelected,
    refInputEl,
    refInputThumbEl,
    previewImg,
    thumbsImg,
    addProductValidation,
    productId,
    certificates,
    certificatesData,
    deliveryDefinitionOptions,
    deliveryDefinitionSelected,
    attachmentConfigSelected,
    attachmentConfigs,
    isRequiredAttachment,
    definitionTitleConfig,
    attachMentTitleConfig,
    statusTitleConfig,
    productDetail,
    is_changed_category,
    isLoading,
    formRegisterProfile,
    originsOption,
    dosageFormsOption,
    categoryOption,
    specificationsOption,
    manufacturersOption,
    handleSelectPreviewImage,
    handleSelectThumbsImage,
    generateSlug,
    priceFormat,
    fetchCategoriesList,
    fetchUnitsList,
    fetchBrandsList,
    fetchDeliveryStatusConfigsList,
    addProduct,
    updateProduct,
    fetchProductById,
    fetchDeliveryDefinitionConfigsList,
    fetchProductCertificates,
    getAttachmentConfigs,
    handleChangeType,
    handleGetConsentForm,
    handleChangeIsMedicine,
    fetchDosageFormList,
    fetchOriginList,
    fetchCategoryList,
    fetchSpecificationList,
    fetchUnitList,
    fetchManufacturerList,
    fetchBrandList,
  }
}
